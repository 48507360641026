export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function urlParser(url) {

  
    // Create a URL object
    let urlObj = new URL(url);

    // Create URLSearchParams object
    let params = new URLSearchParams(urlObj.search);
    let paramObj = {};
    for(var value of params.keys()) {
        paramObj[value] = params.get(value);
    }

    // Add/ Update query parameters
    paramObj.tda= Date.now().toString();
    paramObj.refresh_data=true;

    // Update the URL with the new parameters
    console.log("Objects ",paramObj);
    console.log("String",new URLSearchParams(params).toString())
    urlObj.search = new URLSearchParams(paramObj).toString();

    // Get the updated URL
    let newUrl = urlObj.toString();
    return newUrl;
}